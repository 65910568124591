import * as React from "react"

const divStyles = {
    width: "100%",
}
const div2Styles = {
    width: "50%",
    margin: "auto",
}

const iframeStyles = {
    width: "100%",
    aspectRatio: "1.777", // a hack :)
}

// TODO figure out why the height is wierd
function YoutubeVideo(prop){
    return (
        <div style={divStyles}>
            <div style={div2Styles}>
                <iframe style={iframeStyles} src={prop.src} frameBorder="0" allow="encrypted-media" allowFullScreen title={prop.title}/>
            </div>
        </div>
    )
}

export default YoutubeVideo