import * as React from "react"
import HeaderBar from "../components/HeaderBar"
import FooterBar from "../components/FooterBar"
import GrabcadLink from "../components/GrabcadLink"
import YoutubeVideo from "../components/YoutubeVideo"

import BottomImg from "../images/spark/bottom.png"
import ModuleImg from "../images/project_thumbnails/spark.png"
import SideImg from "../images/spark/sideView.png"
import TopImg from "../images/spark/top.png"
import ImageGallery from "../components/ImageGallery"
import ProjectSeries from "../components/ProjectSeries"

const imgs = [
    {
        img: ModuleImg,
        alt: "A single Wheel Module",
    },
    {
        img: SideImg,
        alt: "Side view (panel removed)",
    },
    {
        img: TopImg,
        alt: "Top down view of entire drivetrain",
    },
    {
        img: BottomImg,
        alt: "Bottom view of entire drivetrain",
    },
]

const SparkDrive = () => {
    return (
        <div>
            {HeaderBar()}
            <h1>Spark Drive</h1>
            <em>The only way to drive in FRC</em>
            {ProjectSeries({
                name: "FRC",
                nextname: "FRC",
                nextlink: "/frc",
                prevname: "FRC",
                prevlink: "/frc",
            })}
            {GrabcadLink("https://grabcad.com/library/spark-drive-1")}
            <p>The Spark Drive is the best way to drive in FRC (if you're willing to put 70+ lbs. in just the drivetrain). The Spark Drive was created primarily by me for team 2848 All Sparks (now 2848 Rangers) for the 2017 (Steamworks) FRC season. The design is based on a modified-butterfly drive. There are some modifications of the butterfly drive which call themselves the same, so I'll begin by describing an original butterfly drive. In a traditional butterfly, all points of contact with the ground are wheel-modules. These modules contain two wheels. These wheels are either driven at different speed and/or are different sizes, allowing for the driver to switch speeds simply by changing the wheels in contact with the ground. Conceptually, the drivetrain is powered by a single speed gearbox, although having shifting gearboxes is not uncommon. One such example of this is see <a href="https://www.chiefdelphi.com/t/pic-butterfly-drive/145732">here</a>. Another key feature of the butterfly drive is that by mixing omni and traction wheels the turning behavior of the robot can be altered. This can be useful when switching from offense to defense.</p>
            <p>In the Spark Drive, the wheels are the same size and geared 1:1, resulting in no speed change when modifying the module. Instead, the wheels are changed solely to alter the driving behavior of the robot. By switching to the omni wheels, the robot can change to a no-resistance turning mode. Furthermore, since the wheels speed remains unchanged, the spark can activate asymmetrically. This is significant because it allows you to pull your axis of rotation to either the front or back axel, behavior that makes placing game pieces significantly easier. Multiple speeds are something that is commonly considered to be important to FRC robots. In the spark drive, the speed change occurs via a shifting gearbox. For the 2017 season, 2848 developed its own 3-cim inline shifting gearbox. Together, these features comprise the Spark Drive.</p>
            <p>In our specific implementation of the Spark Drive, there are a few points to note. The frame is a traditional box sheet metal design. Activation of the butterfly module is achieved by 1.5in bore, 1in stroke pneumatic cylinders. These cylinders where conceptually strong enough to lift the robot (activate the modules). However, due to an unbalanced center of mass, more weight was placed on the back module and the cylinders were unable to actuate, a problem solved by some springs. The design of the modules is also important. By placing the traction wheels inside the omni wheels, the distance between traction wheels is minimized, reducing the power required to turn. However, due to an overaggressive gearing ratio, we still could not turn in high gear without browning out. Additionally, the traction wheels are on the fixed axel about which the module rotates. This prevents any moment from being introduces in the module and transfers any lateral stresses directly to the drivetrain. The omni wheels will have significantly less lateral forces as they roll sideways in the presence of lateral load.</p>
            <p>Another item to note is the stack up on the traction wheel axel. The axel is a hex axel. It rotates freely in the drivetrain. The module rotates freely about this axel. Power is supplied to the traction wheel via this hex axel, which receives its power from a hex-hub gear connected via chain to the gearbox. The omni wheel rotates freely on an idle (dead) axel; it receives its power via plate sprockets mounted on each wheel and chain between them. The mechanical stack up is relatively simple, however, design-packaging and assembly are quite tricky operations. Additionally, the drivetrain weighs in at around 70 pounds, making it quite the hefty investment for a team. Finally, watch the ground clearance you give this thing. 2017 had a completely flat floor, so we started with only 3/8" ground clearance. By the end of the season, we were under 1/8" due to the wheels shrinking as they wear as the season progresses.</p>
            <p>This was and still is one of the coolest things I've designed. It was a very fun robot to drive; to be able to slide off defenders with the omni wheels and Then drop onto tractions and push someone. The drivetrain held up excellently. Aside from the weight issue, there was no maintenance needed. If the season ran longer, we probably would have needed to replace the traction wheels, but it didn't come to that. The ability to change your axis of rotation was also incredible. We didn't use it as much for that reason, but it was helpful when placing pieces on the pegs to be turning near the front rather than sixteen inches behind the piece.</p>
            <ImageGallery value={imgs}/>
            {YoutubeVideo({
                src: "https://www.youtube.com/embed/NUZMADIODME?t=90",
                title: "Spark Drive at Worlds 2017",
            })}
            <p>TODO: find a good clip of the drivetrain in a competition. Perhaps the one against 5431 at Waco?</p>
            {FooterBar()}
        </div>
    )
}

export default SparkDrive